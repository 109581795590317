;(function($){

	function Slides() {
		var _ =  this;

		$('.destaque').slick({
			dots: true,
			arrows: true,
			infinite: false,
			speed: 500,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500
		});

		$('.slider_projetos').slick({
	      dots: false,
	      infinite: true,
	      speed: 300,
	      slidesToShow: 4,
	      slidesToScroll: 1,
	      arrows: true,
	      dots: false,
	      focusOnSelect: true,
	      responsive: [
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            dots: true,
	            arrows: false
	          }
	        }
	      ]
	    });

	    $('.slider_atuacao').slick({
	      dots: false,
	      infinite: true,
	      speed: 300,
	      slidesToShow: 5,
	      slidesToScroll: 1,
	      arrows: true,
	      dots: false,
	      focusOnSelect: true,
	      responsive: [
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            dots: true,
	            arrows: false
	          }
	        }
	      ]
	    });

	    $('.slider-atuacao').slick({
            dots: false,
            arrows: true,
            infinite: true,
            speed: 600,
            cssEase: 'ease',
            arrows: true,
             responsive: [
	        {
	          breakpoint: 480,
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            dots: true,
	            arrows: false
	          }
	        }
	      ]
        });
		
	}
	
	new Slides();

}(jQuery));




