;(function($){

    function Language()
    {
       var iconSelect, 
       icon;     

        iconSelect = new IconSelect("language", 
        {
            'selectedIconWidth':24,
            'selectedIconHeight':24,
            'selectedBoxPadding':1,
            'iconsWidth':24,
            'iconsHeight':24,
            'boxIconSpace':1,
            'vectoralIconNumber':1,
            'horizontalIconNumber':1
        });

        var icons = [];
        
        icons.push({'iconFilePath': $("#app_url").val() + '/public/img/pt.png', 'iconValue':'/pt'});
        icons.push({'iconFilePath': $("#app_url").val() + '/public/img/es.png', 'iconValue':'/es'});
        icons.push({'iconFilePath': $("#app_url").val() + '/public/img/en.png', 'iconValue':'/en'});
        
        iconSelect.refresh(icons);


        switch($("meta[name=language]").attr('content'))
        {
            case 'pt':
                icon = '<img src="'+ $("#app_url").val() +'/public/img/pt.png" width="24" height="16">';
                break;
            case 'es':
                icon = '<img src="'+ $("#app_url").val() +'/public/img/es.png" width="24" height="16">';
                break;
             case 'en':
                icon =  '<img src="'+ $("#app_url").val() +'/public/img/en.png" width="24" height="16">';
                break;
            default:
                icon = '<img src="'+ $("#app_url").val() +'/public/img/pt.png" width="24" height="16">';
        }

        $(document).find("#language .selected-icon").html(icon);

        
        document.getElementById('language').addEventListener('changed', function(e){

           if(iconSelect.getSelectedValue() != $("meta[name=language]").attr('content'))
           {
                window.location = $("#app_url").val() + iconSelect.getSelectedValue();
           }
        });
    }

   	new Language();

}(jQuery));
 
 
 
 
